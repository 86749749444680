@import './../../styles/variable.scss';

.app-login {
    height: 100vh;
    overflow: auto;
    padding: 20px;
    background: radial-gradient(circle, rgba(0,0,0,1) 0%, #400C7A 0%, rgba(0,0,0,1) 100%);

    .welcom-container {
        color: $white;
    }

    .log-line {
        border: 1px solid;
        width: 100%;
        height: 3px;
        background: linear-gradient(90deg, rgba(157, 67, 255, 0) 0%, #FFFFFF 50%, rgba(157, 67, 255, 0) 100%);
    }

    a {
        text-decoration: underline !important;
    }

    .form-check-label {
        font-size: 12px;
    }

    .form-control {
        border: 0;
        border-radius: 55px;
        background-color: #00000040;
        color: #FFFFFF;
        padding: 15px;
        
    }
  


    .input-group {
        
        color: #FFFFFF;

        ::placeholder {
            color: #FFFFFF30;
        }

        .input-group-text {
            color: $danger !important;
            background-color: #00000040;
            border: 0;
            border-radius: 55px;
            font-size: 12px;
        }
    }

    .otp-input {
        width: 100% !important;
        height: 50px;
        max-width: 70px !important;
        border-radius: 10px !important;
    }

    .form-control:focus {
        background-color: #00000040;
        color: #FFFFFF;
    }

}