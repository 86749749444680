@import './../../../styles/variable.scss';

.zuno-profile {
    .back-btn,
    .support-btn {
        height: 35px;
        border-radius: 50%;
        background-color: #9003DE10;
    }

    .back-btn {
        padding: 0;
        background-color: transparent;
    }

    .user-profile {
        align-items: center;

        .user-details {
            text-align: center;
            align-items: center;
            align-content: center;

            .profile-img {
                border: 2px solid $danger;
                padding: 3px;
                margin-right: 10px;
                border-radius: 50%;
                height: 80px;
                width: 80px;
                object-fit: cover;
            }

            p {
                padding: 0;
                margin: 0;
                font-size: 12px;
            }
        }
        .edit-icon {
            border-radius: 50%;
            border: 1px solid $primary;
            height: 50px;
            width: 50px;
            padding: 10px;
            text-align: center;
        }
    }
}