@import './../../../../styles//variable.scss';

.zuno-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 0 30px;
    justify-content: space-between;
    text-align: center;
    color: #7B7B7B;
    font-size: 12px;
    background: linear-gradient(185.38deg, rgba(0, 0, 0, 0) -20.58%, rgba(0, 0, 0, 0.4) 29.99%);
    backdrop-filter: blur(15px);
    .zuno-icons {
        padding-top: 20px;
        width: 60px;
        position: relative;
        text-align: center;
        height: 100%;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
    }

    .active.zuno-icons {
        height: 100%;
        color: $white;
        transition: 0.8s;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        backdrop-filter: blur(10px);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(75, 2, 115, 0.6) 40%, rgba(75, 2, 115, 0.1) 100%);
    }

    .zuno-menu-integator {
        border: 2px solid #9003DE;
        position: absolute;
        width: 20px;
        align-items: center;
        border-radius: 20px;
        top: 5px;
        left: 34%;
    }
}