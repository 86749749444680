// variable.scss

// Override Bootstrap color variables
$primary: #ffc107; // Customize as needed
$secondary: #400C7A; // Customize as needed
$success: #A3D635; // Customize as needed
$info: #17a2b8; // Customize as needed
$warning: #ffc107; // Customize as needed
$danger: #FF6F61; // Customize as needed
$light: #9C9C9C; // Customize as needed
$dark: #343a40; // Customize as needed

// Override other variables as needed
$body-bg: #ffffff;
$body-color: #000000;

$white: #ffffff;
$black: #000000;

@import 'node_modules/bootstrap/scss/bootstrap';
